<template>
  <div class="PageHeader top-0 w-full z-[1001] fixed flex flex-wrap">
    <TestModeBanner
      v-if="testModeStatus && uiStore.isAdmin"
      @set-test-mode="setTestMode"
    />
    <div v-if="isImpersonating" class="bg-accent py-8 text-center w-full text-white z-20">
      Inloggad som {{ impersonateStore.email }}
      <button class="btn btn--secondary btn--sm ml-24" @click="onLogOut">
        Logga ut
      </button>
    </div>
    <div class="bg-primary py-16 relative z-10 w-full">
      <div class="layout-container flex justify-between items-center">
        <nuxt-link :to="isNOMarket ? '' : currentUrl" class="w-120 sm:w-144 flex-shrink-0 mr-12">
          <img
            :src="header.logoUrl"
            class="w-120 sm:w-144 object-contain"
            alt="Swedish Match Logo"
            width="144"
            height="23"
          >
        </nuxt-link>
        <div
          v-if="!isNOMarket"
          ref="searchResultHeaderWrapper"
          class="w-full md:px-40 xl:px-48 md:block"
        >
          <div class="md:relative max-w-[700px] rounded-md">
            <div class="bg-primary absolute left-0 top-0 w-full h-48 transform -translate-y-48 z-30 hidden md:block pointer-events-none" />
            <TextInput
              class="z-40 hidden md:block"
              :has-search-icon="true"
              :placeholder="header.searchPlaceHolder"
              :loading="loading"
              :delay-value="200"
              :small="true"
              :no-border="true"
              :rounded-b="showSearchResultsHeader"
              @on-focus="showSearchResultsHeader = true"
              @submit-input="setSearchPhrase"
              @enter-submit="navigateToSearchPage"
              @click="showSearchResultsHeader = true"
            />
            <transition name="slide-fade-search">
              <SearchResultsHeader
                v-if="showSearchResultsHeader"
                ref="searchResultHeader"
                :search-phrase="searchPhrase"
                :site-notice-banner-height="siteNoticeBannerHeight"
                @close-search-header="showSearchResultsHeader = false, setMetaThemeColor(false)"
                @set-loading="loading = true"
                @stop-loading="loading = false"
              />
            </transition>
          </div>
        </div>
        <div v-if="!isNOMarket" class="flex flex-shrink-0 items-center">
          <div
            v-if="canImpersonate || isImpersonating"
            class="text-white text-small-medium tracking-xl mr-20 cursor-pointer hidden sm:flex items-center"
            @click="showImpersonateModal = true"
          >
            <EyeIcon class="h-20 object-contain mr-6 inline-block align-text-top" />
            <span class="hidden mt-3 lg:inline-block">Impersonate</span>
          </div>
          <div
            v-if="!isLoggedIn"
            class="xsOnly:mr-16 text-white text-small-medium tracking-xl mr-20 hidden items-center md:flex cursor-pointer lg:min-w-[60px]"
            @click="onOpenUserModal(false)"
          >
            <img 
              :src="'/icons/icon-user-white.svg?url'"
              class="h-20 object-contain mr-6 inline-block align-text-top"
              alt="Mitt snus"
              height="20"
              width="20"
            >
            <span
              class="hidden mt-3 lg:inline-block"
            >
              {{ translation.navMyPage }}
            </span>
          </div>
          <a
            v-else
            :href="myAccountPage"
            class="xsOnly:mr-16 text-white text-small-medium tracking-xl mr-20 hidden md:flex lg:min-w-[60px]"
          >
            <img
              :src="'/icons/icon-user-white.svg?url'"
              class="h-20 object-contain mr-6 inline-block mt-px"
              alt="Mitt snus"
              height="20"
              width="20"
            >
            <div class="inline-flex truncate max-w-96">
              <span v-if="uiStore.userFirstName" class="hidden lg:inline-block truncate mt-3">
                {{ uiStore.userFirstName }}
              </span>
              <span v-else class="hidden lg:inline-block truncate  mt-3">
                {{ translation.navMyPage }}
              </span>
            </div>
          </a>
          <nuxt-link
            class="xsOnly:mr-16 text-white text-small-medium tracking-xl mr-20 flex relative items-center cursor-pointer"
            :to="uiStore.isIdentifiedContact ? favouritesPage : ''"
            @click="handleFavouritesLinkClick"
          >
            <BookmarkIcon class="h-20 object-contain mr-6 inline-block align-text-top text-white mt-px" />
            <span class="hidden mt-3 lg:inline-block">{{ translation.navFavorites }}</span>
            <transition name="appear">
              <div
                v-if="favouritesStore.favourites && favouritesStore.totalItemCount > 0"
                :key="favouritesStore.totalItemCount"
                class="absolute top-[-12px] left-[-10px] rounded-full bg-white h-20 w-20 border-4 border-primary text-xxs flex items-center justify-center pt-2 text-black"
              >
                {{ favouritesStore.totalItemCount }}
              </div>
            </transition>
          </nuxt-link>
          <div
            class="xsOnly:mr-16 relative text-white text-small-medium tracking-xl cursor-pointer mr-20 md:mr-0 flex items-center"
            @click="toggleCartMenu"
          >
            <img
              :src="'/icons/icon-shopping-bag-white.svg?url'"
              class="h-20 object-contain mr-6"
              alt="Varukorg"
              height="20"
              width="20"
            >
            <span class="hidden mt-3 lg:inline-block">{{ cartResources.cartHeaderBtn }}</span>
            <transition name="appear">
              <div
                v-if="cartItemsLength && cartItemsLength > 0"
                :key="cartItemsLength"
                class="absolute top-[-12px] left-[-10px] rounded-full bg-success h-20 w-20 border-4 border-primary text-xxs flex items-center justify-center pt-2"
              >
                {{ cartItemsLength }}
              </div>
            </transition>
          </div>
          <div class="text-white md:hidden">
            <img
              :src="'/icons/icon-bars-white.svg?url'"
              class="h-20 object-contain"
              alt="Menu"
              height="20"
              width="20"
              @click="toggleMobileMenu"
            >
          </div>
        </div>
      </div>
      <div v-if="isTablet || isMobile" class="w-full px-12 pt-20 md:hidden">
        <div class="lg:hidden relative md:static">
          <button
            v-if="showSearchResultsHeader"
            class="absolute z-10 top-1/2 transform -translate-y-1/2 right-28 text-xsmall-medium underline"
            @click="showSearchResultsHeader = false"
          >
            Avbryt
          </button>
          <TextInput
            :has-search-icon="true"
            :loading="loading"
            :init-value="searchPhrase"
            :delay-value="300"
            :placeholder="header.searchPlaceHolder"
            :small="true"
            @submit-input="setSearchPhrase"
            @click="showSearchResultsHeader = true"
          />
        </div>
      </div>
      <CartNotice />
      <FavouriteNotice />
    </div>
    <MenuDesktop v-if="!isNOMarket" />
    <transition name="fade-search">
      <div
        v-if="showSearchResultsHeader"
        class="w-full h-full bg-overlay top-0 left-0 fixed"
        @click.self="showSearchResultsHeader = false, setMetaThemeColor(false)"
      />
    </transition>
    <div
      v-if="siteNotices"
      ref="siteNoticeBanner"
      class="site-notices w-full md:order-first z-[1] md:z-30"
    >
      <SiteNotice
        v-for="siteNotice in siteNotices"
        :key="siteNotice.id"
        :site-notice="siteNotice"
        @close-notice="checkNoticesHeight"
      />
    </div>
    <Snackbar v-if="!errorStore.isCartError" class="w-full" />
    <client-only>
      <ImpersonateModal
        v-if="showImpersonateModal"
        @close-impersonate-modal="showImpersonateModal = false"
      />
    </client-only>
  </div>
</template>

<script setup lang="ts">
import TextInput from '~/components/form-elements/TextInput.vue';
import { useGlobalContentStore } from '~/store/globalContent';
import { useImpersonateStore } from '~/store/impersonate';
import SiteNotice from '~/components/body/SiteNotice.vue';
import MenuDesktop from '~/components/body/MenuDesktop.vue';
import CartNotice from '~/components/body/CartNotice.vue';
import FavouriteNotice from '~/components/body/FavouriteNotice.vue';
import TestModeBanner from '~/components/body/TestModeBanner.vue';
import SearchResultsHeader from '~/components/body/SearchResultsHeader.vue';
import ImpersonateModal from '~/components/modals/ImpersonateModal.vue';
import { useUiStore } from '~/store/ui';
import { useCartStore } from '~/store/cart';
import { computed, onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useErrorStore } from '~/store/error';
import BookmarkIcon from '@heroicons/vue/24/outline/esm/BookmarkIcon';
import EyeIcon from '@heroicons/vue/24/outline/esm/EyeIcon';
import { usePageContentStore } from '~/store/pageContent';
import { useFavouritesStore } from '~/store/favourites';
import useDetectOutsideClick from '~/composables/useDetectOutsideClick';
import useDisableScroll from '~/composables/useDisableScroll';
import Snackbar from '~/components/body/Snackbar.vue';

const errorStore = useErrorStore();
const { disableScroll } = useDisableScroll();
const runtimeConfig = useRuntimeConfig();
const header = useGlobalContentStore().header;
const siteNotices = useGlobalContentStore().siteNotices;
const uiStore = useUiStore();
const cartStore = useCartStore();
const globalContentStore = useGlobalContentStore();
const myAccountPage = globalContentStore.pageSettings.myAccountPage.url;
const favouritesPage = globalContentStore.pageSettings.favouritesPage.url;
const cartResources = globalContentStore.cartResources;
const translation = globalContentStore.menuResources;
const { isLoggedIn, canImpersonate, isImpersonating } = storeToRefs(uiStore);
const showSearchResultsHeader = ref(false);
const searchPhrase = ref('');
const searchResultHeader = ref();
const loading = ref(false);
const siteNoticeBanner = ref();
const siteNoticeBannerHeight = ref(0);
const { isMobile, isTablet } = storeToRefs(useUiStore());
const showImpersonateModal = ref(false);
const impersonateStore = useImpersonateStore();
const searchResultsPage = globalContentStore.pageSettings?.searchResultsPage;
const pageStore = usePageContentStore();
const favouritesStore = useFavouritesStore();
const marketSettings = useGlobalContentStore().marketSettings;
const markets = marketSettings.markets;
const shouldNavigateToFavourites = ref();

defineProps<{
  testModeStatus?: boolean,
}>();

const isNOMarket = computed(() => {
  return marketSettings.countryCode === 'NO';
});

const currentUrl = computed(() => {
  const currentMarket = markets.find((i) => {
    return i.isCurrentMarket && marketSettings.contentLanguage === i.language;
  });

  if (currentMarket) {
    if (currentMarket.url?.includes('https:')) {
      return currentMarket.url;
    } else {
      return currentMarket.url?.replace(/^http:/, 'https:');
    }
  }
  return '/';
});

const cartItemsLength = computed(() => {
  return cartStore.itemsLength;
});

const toggleMobileMenu = () => {
  uiStore.toggleShowMobileMenu();
};
const toggleCartMenu = () => {
  uiStore.setShowCartMenu(true);
  disableScroll();

  window.dataLayer?.push({
    event: 'Cart',
    category: 'cart',
    action: 'view',
  });
};

const checkNoticesHeight = (() => {
  if (siteNoticeBanner.value) {
    // wait for animtation
    setTimeout(() => {
      emit('check-notices-height', siteNoticeBanner.value.clientHeight);
      siteNoticeBannerHeight.value = siteNoticeBanner.value.clientHeight;
    }, 25);
  }
});

const onOpenUserModal = (preventLoginNavigate: boolean) => {
  uiStore.setShowLogin(true);
  disableScroll();
  if (preventLoginNavigate) {
    uiStore.setPreventLoginNavigate(true);
  }
};

const handleFavouritesLinkClick = () => {
  if (uiStore.isIdentifiedContact) return;
  onOpenUserModal(true);
  shouldNavigateToFavourites.value = true;
};

const setSearchPhrase = (input: string) => {
  searchPhrase.value = input;
  searchResultHeader.value?.performSearch(searchPhrase.value);
};

const searchResultHeaderWrapper = ref();

useDetectOutsideClick(searchResultHeaderWrapper, () => {
  if (!isTablet.value && !isMobile.value) {
    showSearchResultsHeader.value = false;
  }
});

const router = useRouter();
const navigateToSearchPage = () => {
  showSearchResultsHeader.value = false;

  // Make sure that searchphrase is set first
  loading.value = false;
  setTimeout(() => {
    router.push({ path: searchResultsPage.url, query: { query: searchPhrase.value } });
  }, 100);
};

// Impersonating
const fetchUser = async() => {
  await impersonateStore.fetchUser();
};

if (isImpersonating.value) {
  fetchUser();
}

const onLogOut = async() => {
  try {
    const res = await $fetch(`${runtimeConfig.public.apiUrl}user/impersonate`, {
      method: 'DELETE',
      body: {
      },
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': globalContentStore.getAcceptLanguage,
        'RequestVerificationToken': globalContentStore.getTheAntiForgeryToken,
      },
    });
    if (res && !res.success) {
      errorMessage.value = res.errorMessage;
    } else {
      window.location.href = '/externallogin/?returnUrl=/';
    }
  } catch (e: any) {
    console.log(e);
  }
};

const setTestMode = () => {
  emit('deactivate-test-mode', false);
};

const setMetaThemeColor = (value: boolean) => {
  // Set meta color of age gate is open
  const metaColor = document.querySelector('meta[name="theme-color"]');
  if (metaColor && value) {
    metaColor.setAttribute('content', '#00455D');
  } else if (metaColor) {
    metaColor.setAttribute('content', '#00455D');
  }
};

const emit = defineEmits<{
  (event: 'deactivate-test-mode', value: boolean): void,
  (event: 'check-notices-height', value: number): void,
}>();

watch(isImpersonating, (newVal) => {
  if (newVal) {
    fetchUser();
  }
});

// close search when route change
const route = useRoute();
watch(
  () => route.fullPath,
  () => {
    showSearchResultsHeader.value = false;
    setMetaThemeColor(false);
  }
);

watch(
  () => pageStore.pageType,
  () => {
    if (pageStore.pageType.slice(-1)[0] !== 'SearchResultsPage') {
      searchPhrase.value = '';
    }
  }
);

watch(
  () => isLoggedIn.value,
  () => {
    if (isLoggedIn.value && shouldNavigateToFavourites.value && favouritesPage) {
      window.location.href = favouritesPage;
    }
  }
);

watch(
  () => isImpersonating.value,
  () => {
    if (!impersonateStore.email) {
      fetchUser();
    }
  }
);
</script>

<!--suppress CssUnusedSymbol -->
<style lang="postcss">

.appear-enter-active,
.appear-leave-active {
  transition: all 0.5s ease-in-out;
  /* transform: scale(120%); */
  animation: grow-in 2s reverse;
  /* border-color: #477586; */
}

.appear-enter-from,
.appear-leave-to {
  opacity: 0;
  /* transform: scale(100%); */
}

@keyframes grow-in {
  0% {
    transform: scale(1);
    border-color: #00455D;
  }
  10% {
    transform: scale(1.25);
    border-color: #477586;
  }
  50% {
    transform: scale(1.25);
    border-color: #477586;
  }
  90% {
    transform: scale(1.25);
    border-color: #477586;
  }
  100% {
    transform: scale(1);
    border-color: #00455D;
  }
}
</style>
