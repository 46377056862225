<template>
  <div 
    :class="{
      'checkout-page': isCheckout,
    }"
    @click="counter += 1"
  >
    <transition name="modal">
      <AgeAgreementForm
        v-if="
          marketSettings.countryCode === 'CH' &&
            !ageAgreementFormClosed &&
            ageAgreementFormCookie === undefined
        "
        @close-age-form="closeAgeAgreementForm"
      />
      <AgeGate
        v-else-if="
          !ageGateClosed &&
            ageGateCookie === undefined &&
            (marketSettings.countryCode === 'SE')
        "
        @close-age="closeAge"
      />
    </transition>
    <MenuMobile v-if="isTablet || isMobile && !isNOMarket" />
    <CartMenu v-if="!isNOMarket" />
    <client-only>
      <transition name="modal">
        <ModalsLoginModal v-if="showLogin && !isNOMarket" />
      </transition>
    </client-only>
    <PageHeader
      v-if="!isCheckout && !isReceipt"
      :test-mode-status="testModeStatus"
      @deactivate-test-mode="deactivateTestMode"
      @check-notices-height="checkNoticesHeight"
    />
    <main
      :class="{
        'min-h-screen': isEpiDefault || pageType || true,
        'mt-[calc(52px+60px)] md:!mt-0 md:pt-[130px]': !isCheckout,
        'bg-grey200': isCheckout || isMyPages,
        '!mt-0 !pt-0': isCheckout
      }"
      class="main pb-64 relative sm:pb-0"
      :style="siteNotices.length ? siteNoticesHeight : ''"
    >
      <HealthWarning v-if="!isCheckout && !isReceipt && !isNOMarket" />
      <slot />
      <client-only>
        <GlobalsScrollToTop
          v-if="isMobile && !isCheckout && !isNOMarket"
          class="absolute w-full bottom-4"
        />
      </client-only>
    </main>
    <PageFooter
      v-if="!isCheckout && !isReceipt && !isNOMarket"
      ref="pageFooter"
      :test-mode-status="testModeStatus"
      @set-test-mode="setTestMode"
    />
    <transition name="slide-fade">
      <QuickMenu v-if="!isCheckout && isScrolledUp && !isReceipt && !isNOMarket" />
    </transition>
    <client-only>
      <transition name="modal">
        <NewsletterModal
          v-if="!isNOMarket && !showLogin && !newsletterSettings.inactivateNewsletterModal && (ageGateClosed || (ageGateCookie !== undefined && ageGateCookie == '1'))"
          :click-counter="counter"
        />
      </transition>
    </client-only>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { storeToRefs } from 'pinia';
import { useUiStore } from '~/store/ui';
import { usePageContentStore } from '~/store/pageContent';
import { useGlobalContentStore } from '~/store/globalContent';
import { useModalStore } from '~/store/modal';
import { useApplicationInsights } from '~/store/applicationInsights';
import PageHeader from '~/components/body/PageHeader.vue';
import HealthWarning from '~/components/body/HealthWarning.vue';
import PageFooter from '~/components/body/PageFooter.vue';
import QuickMenu from '~/components/body/QuickMenu.vue';
import MenuMobile from '~/components/body/MenuMobile.vue';
import CartMenu from '~/components/body/CartMenu.vue';
import AgeGate from '~/components/modals/AgeGate.vue';
import AgeAgreementForm from '~/components/modals/AgeAgreementForm.vue';
import useCheckScroll from '~/composables/useCheckScroll';
import NewsletterModal from '~/components/body/NewsletterModal.vue';
import useDisableScroll from '~/composables/useDisableScroll';
import { useErrorStore } from '~/store/error';

const { disableScroll, enableScroll } = useDisableScroll();
const uiStore = useUiStore();
const { isMobile, isTablet, showMobileMenu, showLogin, isImpersonating } = storeToRefs(uiStore);
const pageStore = usePageContentStore();
const { isEpiDefault } = storeToRefs(pageStore);
const route = useRoute();
const counter = ref(0);
const { isScrolledUp } = useCheckScroll();
const marketSettings = useGlobalContentStore().marketSettings;
const ipCountry = ref();
const modalStore = useModalStore();
const pageType = ref(useGlobalContentStore().pageType);
const newsletterSettings = useGlobalContentStore().newsletterSettings;
const siteNotices = useGlobalContentStore().siteNotices;
const globalContentStore = useGlobalContentStore();
const errorStore = useErrorStore();
const headers = globalContentStore.headers;
const host = globalContentStore.host;

const isNOMarket = computed(() => {
  return marketSettings.countryCode === 'NO';
});

const isCheckout = computed(() => {
  return pageType.value === 'CheckoutPage';
});

const isMyPages = computed(() => {
  return pageType.value === 'MyAccountPage';
});

const isProductListingPage = computed(() => {
  return pageType.value === 'ProductListingPage';
});

const isProductPage = computed(() => {
  return pageType.value === 'ProductPage';
});

// Add padding top if has site notices
const siteNoticesHeight = computed(() => {
  const visibleNotices = siteNotices.filter(siteNotice => {
    const siteNoticeCookie = useCookie('site-notice-' + siteNotice.id);
    return !siteNoticeCookie.value;
  });
  const hasErrorSnackbar = errorStore.errorMessage && !errorStore.isCartError;
  const snackbarHeight = hasErrorSnackbar ? 36 : 0;

  if (isMobile.value || isTablet.value) {
    return `margin-top: ${snackbarHeight + 52 + 60 + visibleNotices.length * 40}px`;
  } else {
    return `padding-top: ${snackbarHeight + 130 + visibleNotices.length * 40}px`;
  }
});

// Receipt
const isReceipt = ref(false);
if (route.fullPath.includes('order-receipt')) {
  isReceipt.value = true;
}

// Creates a css variable for 100vh that works on ios safari
const setWindowHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

// Detects mobile
const updateWidth = () => {
  uiStore.setIsMobile(window.innerWidth <= 767);
  uiStore.setIsTablet(window.innerWidth > 640 && window.innerWidth <= 1024);
};

// test mode
const testModeStatus = ref(false);
const pageFooter = ref();
const setTestMode = (value: boolean) => {
  testModeStatus.value = value;
  checkNoticesHeight();
};
const deactivateTestMode = () => {
  pageFooter.value.toggleTestMode();
  checkNoticesHeight();
};

// Get notices height
const checkNoticesHeight = (() => {
  const main = document.querySelector('main');
  const notices = document.querySelectorAll('.site-notices');
  const noticeHeight = notices[0] ? notices[0].clientHeight : 0;
  let impersonateBannerHeight = 0;
  let testModeBannerHeight = 0;

  if (isImpersonating.value) impersonateBannerHeight = 50;
  if (testModeStatus.value) testModeBannerHeight = 62;

  if (main && !isCheckout.value) {
    main.style.paddingTop = `${130 + impersonateBannerHeight + testModeBannerHeight + noticeHeight}px`;
    main.style.marginTop = `${112 + impersonateBannerHeight + testModeBannerHeight + noticeHeight}px`;
  }
});

// ageGate stuff
const ageGateClosed = ref(false);
const ageGateCookie = useCookie('ageGateCookie', { maxAge: 15768000, path: '/' });
const closeAge = () => {
  ageGateCookie.value = '1';
  ageGateClosed.value = true;

  setMetaThemeColor(false);
};

// ageAgreementForm stuff
const ageAgreementFormClosed = ref(false);
const ageAgreementFormCookie = useCookie('ageAgreementFormCookie', { maxAge: 15768000, path: '/' });
const closeAgeAgreementForm = () => {
  ageAgreementFormCookie.value = '1';
  ageAgreementFormClosed.value = true;

  setMetaThemeColor(false);
};

onMounted(()=> {
  if (isEpiDefault.value) {
    setWindowHeight();
    window.addEventListener('resize', setWindowHeight);
    updateWidth();
    window.addEventListener('resize', updateWidth);
    window.addEventListener('scroll', handleScroll);
  }

  if (process.env.NODE_ENV === 'development') {
    ipCountry.value = route.query.ipCountry ? route.query.ipCountry : 'SE';
  } else {
    ipCountry.value = modalStore.ipCountry;
  }

  sessionStorage.removeItem('has-history-on-page');

  if (!ageGateClosed.value) {
    setMetaThemeColor(true);
  }

  if (!isProductListingPage.value && !isProductPage.value) {
    // checkNoticesHeight();
  }

  loadZendeskChat();
});

onUnmounted(() => {
  window.removeEventListener('resize', setWindowHeight);
  window.removeEventListener('resize', updateWidth);
});

const setMetaThemeColor = (value: boolean) => {
  const metaColor = document.querySelector('meta[name="theme-color"]');
  if (metaColor && value) {
    metaColor.setAttribute('content', '#00374a');
  } else if (metaColor) {
    metaColor.setAttribute('content', '#00455D');
  }
};

const hasScrolled = ref(false);
const handleScroll = () => {
  if (!hasScrolled.value) {
    hasScrolled.value = true;
  }
};

// Load the Zendesk script with a 15s delay, as it's impacting the Lighthouse score
const loadZendeskChat = async() => {
  setTimeout(() => {
    const script = document.createElement('script');
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=9c110705-dd35-4e75-b2ca-52f8f31205aa';
    script.setAttribute('id', 'ze-snippet');
    script.async = true;
  
    document.head.appendChild(script);

    window.zdonload = setInterval(() => {
      if (typeof zE !== 'undefined' && typeof zE.activate !== 'undefined') {
        clearInterval(window.zdonload);
        zE('webWidget:on', 'chat:start', function() {
          $fetch('/api/user/interactions/', {
            method: 'POST',
            body: JSON.stringify({
              schemaId: 'ConsumerCareChatStarted',
            }),
          });
        });
        zE('webWidget:on', 'chat:end', function() {
          $fetch('/api/user/interactions/', {
            method: 'POST',
            body: JSON.stringify({
              schemaId: 'ConsumerCareChatEnded',
            }),
          });
        });
      }
    }, 50, null);
  }, 15000);
  
};

watch(showMobileMenu,(showing) => {
  if (showing) {
    document.body.classList.add('noscroll');
    disableScroll();
  } else {
    document.body.classList.remove('noscroll');
    enableScroll();
  }
});

watch(
  () => pageStore.pageType,
  () => {
    if (pageStore.pageType.slice(-1)[0]) { 
      pageType.value = pageStore.pageType.slice(-1)[0];
    }
  }
);

useHead({
  meta: [
    {
      name: 'theme-color',
      content: '#00455D',
    },
  ],
});
</script>
<style lang="postcss">
@media screen and (max-width: 1024px) {
  .main {
    padding-top: 0 !important;
  }
}
</style>
