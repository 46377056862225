<template>
  <div>
    <ModalWrapper
      v-if="modalOpen"
      :headline="sharedResources.newsletterModalHeading"
      :closeable="true"
      content-class=""
      @close="hideModal"
    >
      <div v-if="newsletterAccepted">
        <div
          class="wysiwyg"
          v-html="newsletterSettings.confirmationMessage"
        />
      </div>
      <div v-else>
        <div class="flex text-heading-xl mb-8">
          <EnvelopeIcon class="h-24 w-24 mr-12" />
          {{ newsletterSettings.modalTitle }}
        </div>

        <div class="wysiwyg wysiwyg--sm-text mb-16" v-html="newsletterSettings.modalMainBody" />
        <form @submit.prevent="submitNewsletterSubscription">
          <TextInput
            :id="'discountCodeInput'"
            :placeholder="'E-postadress'"
            :form-input="true"
            :error="emailError"
            :init-value="defaultValue"
            class="w-full"
            @submit-input="emailInput"
            @enter-submit="submitNewsletterSubscription"
          />
          <div
            v-if="wrongEmail"
            class="mb-12 cursor-pointer"
            @click="fixEmail"
            v-html="wrongEmail"
          />
          <div v-if="!newsletterSettings.inactivateCreateAccount">
            <div class="flex text-heading-xl mt-16 mb-8">
              <img
                :src="'/icons/icon-user.svg?url'"
                class="h-24 w-24 mr-12"
                alt="Mitt snus"
                height="24"
                width="24"
              >
              {{ newsletterSettings.createAccountTitle }}
            </div>
            <div class="wysiwyg wysiwyg--sm-text mb-16" v-html="newsletterSettings.createAccountMainBody" />
            <Checkbox
              class="mb-12"
              :checked="register"
              :text="translation.registerFromNewsletter"
              @on-check="register = !register"
            />
            <div 
              v-if="register"
            >
              <div class="text-xsmall-medium sm:text-small-medium mb-12">
                {{ translation3.signupTitle }}
              </div>
              <div class="mb-12">
                <div class="h-40 sm:h-48">
                  <input
                    v-model="firstName"
                    type="text"
                    autocomplete="given-name"
                    class="form-input"
                    :class="{
                      'has-error form-input--error': backendValidation.FirstName,
                    }"
                    :placeholder="translation3.signupFirstName"
                    @keydown="checkLetterOnly"
                    @input="backendValidation.FirstName = ''"
                  >
                </div>
                <div v-if="backendValidation.FirstName" class="mt-6 text-xsmall-medium text-error">
                  {{ backendValidation.FirstName }}
                </div>
              </div>
              <div class="mb-12">
                <div class="h-40 sm:h-48">
                  <input
                    v-model="lastName"
                    type="text"
                    autocomplete="family-name"
                    class="form-input"
                    :class="{
                      'has-error form-input--error': backendValidation.LastName,
                    }"
                    :placeholder="translation3.signupLastName"
                    @keydown="checkLetterOnly"
                    @input="backendValidation.LastName = ''"
                  >
                </div>
                <div v-if="backendValidation.LastName" class="mt-6 text-xsmall-medium text-error">
                  {{ backendValidation.LastName }}
                </div>
              </div>
              <div class="mb-8">
                <div class="relative h-40 sm:h-48">
                  <input
                    v-model="phoneNumber"
                    type="tel"
                    :placeholder="translation3.signupPhone + ' (' + translation3.optional + ')'"
                    autocomplete="phone"
                    class="form-input"
                    :class="{
                      'has-error form-input--error': backendValidation.PhoneNumber,
                    }"
                    @input="backendValidation.PhoneNumber = ''"
                  >
                </div>
                <div v-if="backendValidation.PhoneNumber" class="mt-6 text-xsmall-medium text-error">
                  {{ backendValidation.PhoneNumber }}
                </div>
              </div>
              <div class="mb-12">
                <div class="relative h-40 sm:h-48">
                  <input
                    v-model="password"
                    :type="showPassword ? 'text': 'password'"
                    autocomplete="new-password"
                    class="form-input"
                    :class="{
                      'form-input--error': backendValidation.Password || passwordError,
                    }"
                    :placeholder="translation3.signupPassword"
                    @input="backendValidation.Password = '', passwordError = ''"
                  >
                  <div
                    class="absolute top-0 right-0 h-40 w-40 flex items-center justify-center sm:w-48 sm:h-48"
                    @click.stop="toggleShowPassword"
                  >
                    <img
                      :src="showPassword ? '/heroicons/outline/eye-slash.svg' : '/heroicons/outline/eye.svg'"
                      alt="Password visibility"
                      class="inline-block h-20 w-20"
                      height="20"
                      width="20"
                    >
                  </div>
                </div>
                <div v-if="passwordError || backendValidation.Password" class="mt-6 text-xsmall-medium text-error">
                  {{ passwordError || backendValidation.Password }}
                </div>
              </div>
              <div class="mb-16">
                <div class="relative h-40 sm:h-48">
                  <input
                    v-model="password2"
                    :type="showPassword2 ? 'text': 'password'"
                    autocomplete="new-password"
                    class="form-input"
                    :class="{
                      'form-input--error': backendValidation.ConfirmPassword,
                    }"
                    :placeholder="translation3.signupPasswordConfirm"
                  >
                  <div
                    class="absolute top-0 right-0 h-40 w-40 flex items-center justify-center sm:w-48 sm:h-48"
                    @click.stop="toggleShowPassword2"
                  >
                    <img
                      :src="showPassword2 ? '/heroicons/outline/eye-slash.svg' : '/heroicons/outline/eye.svg'"
                      alt="Password visibility"
                      class="inline-block h-20 w-20"
                      height="20"
                      width="20"
                    >
                  </div>
                </div>
                <div v-if="backendValidation.ConfirmPassword" class="mt-6 text-xsmall-medium text-error">
                  {{ backendValidation.ConfirmPassword }}
                </div>
              </div>
            </div>
          </div>     
          <div
            v-if="generalError"
            class="bg-error mb-8 rounded-md text-white p-12 flex items-center"
          >
            <ExclamationCircleIcon
              class="w-24 text-white mr-12"
            />
            {{ generalError }}
          </div>
          <GlobalsButton
            :text="register ? sharedResources.registerAndNewsletterModalCta : sharedResources.newsletterModalCta"
            :type="'submit'"
            :loading="loading"
            :no-validate="true"
            class="btn btn--primary w-full"
          />
        </form>
        <div class="wysiwyg wysiwyg--tiny-text mt-8" v-html="newsletterSettings.termsAndConditions" />
      </div>
    </ModalWrapper>
  </div>
</template>

<script lang="ts" setup>
import ModalWrapper from '~/components/modals/ModalWrapper.vue';
import EnvelopeIcon from '@heroicons/vue/24/outline/esm/EnvelopeIcon';
import { useUiStore } from '~/store/ui';
import { useGlobalContentStore } from '~/store/globalContent';
import TextInput from '~/components/form-elements/TextInput.vue';
import { ref } from 'vue';
import emailMisspelled, { top100 } from 'email-misspelled';
import { useErrorStore } from '~/store/error';
import { useVoyadoStore } from '~~/store/voyado';
import { storeToRefs } from 'pinia';
import useDisableScroll from '~/composables/useDisableScroll';
import { type IICommandResultBase } from '~/api-types';
import Checkbox from '~/components/form-elements/Checkbox.vue';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import ExclamationCircleIcon from '@heroicons/vue/24/outline/esm/ExclamationCircleIcon';
const { disableScroll, enableScroll } = useDisableScroll();
const emailChecker = emailMisspelled({ domains: top100 });
const uiStore = useUiStore();
const { contactId } = storeToRefs(uiStore);
const errorStore = useErrorStore();
const globalContentStore = useGlobalContentStore();
const voyadoStore = useVoyadoStore();
const { apiPost } = useApiFetch();
const translation = globalContentStore.sharedResources;
const translation2 = globalContentStore.registrationResources;
const translation3 = globalContentStore.myPageResources;
const marketSettings = globalContentStore.marketSettings;
const actionButtonClicked = ref(false);
const register = ref(false);

interface Props {
  clickCounter?: number,
}

const props = withDefaults(defineProps<Props>(), {
  clickCounter: 0,
});

const loading = ref(false);
const fieldError = ref(false);
const generalError = ref('');
const firstName = ref('');
const lastName = ref('');
const phoneNumber = ref('');
const password = ref('');
const passwordError = ref('');
const password2 = ref('');
const backendValidation = ref({
  Email: '',
  FirstName: '',
  LastName: '',
  Password: '',
  ConfirmPassword: '',
  PhoneNumber: '',
});
const showPassword = ref(false);
const showPassword2 = ref(false);
const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
};
const toggleShowPassword2 = () => {
  showPassword2.value = !showPassword2.value;
};

const resetErrors = () => {
  backendValidation.value =
    {
      Email: '',
      FirstName: '',
      LastName: '',
      Password: '',
      ConfirmPassword: '',
      PhoneNumber: '',
    };
  generalError.value = '';
};

const checkLetterOnly = (event: KeyboardEvent) => {
  const key = event.key;
  const letterRegex = /^[^a-zA-Z\u00C0-\u024F]$/;
  if (key && key.length === 1 && letterRegex.test(key)) {
    event.preventDefault();
  }
};

onMounted(() => {
  const buttons = document.querySelectorAll('.btn--primary');
  buttons.forEach(button => {
    button.addEventListener('click', () => {
      actionButtonClicked.value = true;
    });
  });
  document.body.addEventListener('click', (event) => {
    const clickedElement = event.target;
    if (
      clickedElement &&
      !clickedElement.classList.contains('btn--primary') &&
      !(clickedElement.parentElement && clickedElement.parentElement.classList.contains('btn--primary'))
    ) {
      actionButtonClicked.value = false;
    }
  });
});

const sharedResources = globalContentStore.sharedResources;

const newsletterSettings = globalContentStore.newsletterSettings;
const newsletterAccepted = ref(false);

const newsletterClosedCookie = useCookie('newsletter-closed-cookie', {
  maxAge: 3 * 60 * 60 * 24,
});

const modalOpen = computed(() => {
  if (uiStore.showNewsletterModal) disableScroll();
  return uiStore.showNewsletterModal;
});

const hideModal = () => {
  uiStore.setShowNewsletterModal(false);
  newsletterClosedCookie.value = 'true';
  enableScroll();
};

const email = ref('');
const emailError = ref('');
const defaultValue = ref('');

const wrongEmail = ref('');
const suggestion = ref('');
const checkEmail = () => {
  const test = emailChecker(email.value);
  if (test.length && test[0].corrected) {
    wrongEmail.value = `Menade du <span class="underline">${test[0].corrected}`;
    suggestion.value = test[0].corrected;
  }
};
const fixEmail = () => {
  email.value = suggestion.value;
  defaultValue.value = suggestion.value;
  wrongEmail.value = '';
};

const emailInput = (input: string) => {
  email.value = input;

  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input.trim()) && input) {
    emailError.value = '';
    checkEmail();
  } else if (!input) {
    emailError.value = '';
  } else {
    emailError.value = sharedResources.invalidEmail;
  }
};

const submitNewsletterSubscription = async() => {
  if (!email.value) {
    emailError.value = sharedResources.emptyEmailError;
  }

  if (register.value) {
    fieldError.value = false;
    resetErrors();
    if (!emailError.value && email.value) {
      if (!firstName.value) {
        backendValidation.value.FirstName = translation.emptyFieldError;
        fieldError.value = true;
      }

      if (!lastName.value) {
        backendValidation.value.LastName = translation.emptyFieldError;
        fieldError.value = true;
      }
      // Check phone field
      // If valid, parsePhonenumber when saving
      if (phoneNumber.value) {
        let valid = false;
        try {
          valid = isValidPhoneNumber(phoneNumber.value, marketSettings.countryCode);
        } catch (e) {
          console.log(e);
          valid = false;
        }
        if (!valid) {
          backendValidation.value.PhoneNumber = translation.invalidPhoneNumber;
          fieldError.value = true;
        } else if (valid) {
          phoneNumber.value = parsePhoneNumber(phoneNumber.value, marketSettings.countryCode).number;
        }
      }
      
      if (password.value !== password2.value) {
        passwordError.value = translation2.passwordsMustMatch;
        return;
      }

      if (fieldError.value) {
        return;
      }
      loading.value = true;

      const payload = {
        countryCode: marketSettings.countryCode,
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        phoneNumber: phoneNumber.value,
        password: password.value,
        confirmPassword: password2.value,
        signUpToNewsletter: true,
        returnUrl: "/kundservice/"
      };
      const result = await apiPost<IICommandResultBase>(
        'user',
        payload
      );

      if (result?.success) {
        loading.value = false;
        newsletterAccepted.value = true;
        if (result.userNotVerified === false) {
          await uiStore.checkLoggedIn();
        }

        window.dataLayer?.push({
          event: 'newsletter_signup',
        });

        window.dataLayer?.push({
          event: 'Registration',
          category: 'user',
          action: 'newuser',
        });
      } else if (result) {
        loading.value = false;

        if (result.errorMessage) {
          generalError.value = result.errorMessage;
        }
        if (result.validationErrors.length) {
          result.validationErrors.forEach((i) => {
            if (i.propertyName) {
              backendValidation.value[i.propertyName] = i.displayMessage;
            }
          });
        }
      } else {
        loading.value = false;

        generalError.value = translation3.errorSomethingWentWrong;
      }
      return;
    }
  }
  if (!emailError.value && email.value) {
    const res = await apiPost<IICommandResultBase>(
      'newsletter',
      {
        countryCode: globalContentStore.marketSettings.countryCode,
        email: email.value,
      }
    );

    if (res.success) {
      newsletterAccepted.value = true;
  
      window.dataLayer?.push({
        event: 'newsletter_signup',
      });
  
      await uiStore.checkLoggedIn();
      voyadoStore.setContactId(contactId.value);
    } else {
      errorStore.displayError({ message: res.errorMessage });
    }
  }
};

watch(
  () => props.clickCounter,
  () => {
    if (
      newsletterSettings.clicksToTrigger != null &&
      props.clickCounter >= newsletterSettings?.clicksToTrigger &&
      !uiStore.userNewsletterStatus &&
      !newsletterClosedCookie.value &&
      !actionButtonClicked.value
    ) {
      setTimeout(() => {
        uiStore.setShowNewsletterModal(true);
      }, 1000);
    }
  }
);

useHead({
  meta: [
    {
      name: 'theme-color',
      content: '#00374a',
    },
  ],
});

</script>
